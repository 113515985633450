import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import { useStaticQuery, graphql } from "gatsby"

import CategoryFilter from "./Sections/CategoryFilter"
import ServiceFilter from "./Sections/ServiceFilter"
import SectorFilter from "./Sections/SectorFilter"
import NewsTypeFilter from "./Sections/NewsTypeFilter"

import {InsightsFilters} from "../../../queries/common_use_query"

import { useContainerContext } from "../../../store/ContainerContext";
import Newsletter from "../../forms/newsletter-form-popup"

const BannerFilter = (props) => {

    const context = useContainerContext();
    const {filter_by_cat, filter_by_serv, filter_by_sec} = context.state;
    const {FilterByCatDispatch, FilterByServiceDispatch, FilterBySectorDispatch} = context.actions;
    
    const { loading, error, data } = InsightsFilters();

    const [isOpen,setIsOpen] = useState(false);

    const [categoryName,setCategoryName] = useState("");
    const global_data = useStaticQuery(graphql`
    query subscribeQuery {  
      glstrapi{

        globalModule {
            Subscribe_Updates {
              Title
              Content
            }
        }

      }
    }`)
    var Subscribe_Updates = global_data.glstrapi?.globalModule?.Subscribe_Updates;
    const handlerSearchClick = (cname) => {
        setCategoryName(cname);
    }

    let newsAndInsights = []
    if (data && data.newsAndInsights && data.newsAndInsights.length > 0) {
        newsAndInsights = data.newsAndInsights;
        if(props.alias){
            newsAndInsights = data.newsAndInsights.filter(item => (item.News_Type.toLowerCase().includes(props.alias)|| item.News_Type == "all")); 
        } 
    }
    return (
        <React.Fragment>
            <div className="banner-bottom banner_filter">
                <Container>
                    <Row>
                        <Col>
                            <div className="bottom-wrapper">

                                <div className="select-box-block">
                                    {/* <NewsTypeFilter data={data && data.newsAndInsights} filter_by_val={filter_by_cat} FilterByDispatch={FilterByCatDispatch}/> */}

                                    <CategoryFilter handleClikCat={handlerSearchClick} data={newsAndInsights} filter_by_val={filter_by_cat} FilterByDispatchSer={FilterByServiceDispatch} FilterByDispatch={FilterByCatDispatch}/>

                                    {/* <ServiceFilter category_name={categoryName} data={data && data.newsAndInsights} filter_by_val={filter_by_serv} FilterByDispatchSer={FilterByServiceDispatch} /> */}

                                    {/* <SectorFilter data={data && data.newsAndInsights} filter_by_val={filter_by_sec} FilterByDispatch={FilterBySectorDispatch} /> */}
                                </div>
                                <div className="subscribe">
                                    <a href="javascript:;"  onClick={()=>setIsOpen(true)}><i className="icon-msg"></i> Subscribe to email updates</a>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                id="popup_modal_form"
                show={isOpen}
                onHide={() => setIsOpen(false)}
                backdrop="static"
                keyboard={false}
                className="member-contact-modal"
                dialogClassName="modal-dialog-centered"
            >
                {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}
                <Modal.Header closeButton className="contact-close-btn memberPopup">
                    {
                        Subscribe_Updates && 
                        <Modal.Title className="w-100">
                            <h3 className="text-center content-primary-color mb-0">{Subscribe_Updates.Title}</h3>
                            <p className="mb-0">{Subscribe_Updates.Content}</p>
                        </Modal.Title>
                    }
                    
                </Modal.Header>
                <Modal.Body>
                    <Newsletter />
                </Modal.Body>
            </Modal>
        </React.Fragment >
    );
};
export default BannerFilter;
